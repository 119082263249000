import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { TransactionService } from 'src/app/services/transaction.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class ContributionsEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private transactionervice: TransactionService) {
  }

  getContributions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.transactionervice.getContributions(action.memberId, action.accountId).pipe(
      map((response: any) => {
        return ActionsList.ResponseAction({ payload: response });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
