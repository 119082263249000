<div class="section-title d-flex ">
  Contributions Limits
</div>
<div class="card" *ngIf="data$ | async; let data">

  <div class="description" *ngIf="data.description">
    {{data.description}}
  </div>

  <div class="d-flex flex-row  widgets-row " >
    <div class="w-50 widget">
      <h4 class="title">Concessional</h4>
      <div class="gauge gauge__liveupdate" id="gauge">
        <div class="gauge--container" id="gauge-container">
          <div class="gauge--marker"></div>
          <div class="gauge--background"></div>
          <div class="gauge--center"></div>
          <div class="gauge--data"
            style="transform: rotate(-{{calculatePercentage(data.concessionalAmount,data.concessionalCap)}}deg);">
          </div>
          <div class="gauge--data over" *ngIf="data.concessionalExceeded" style="transform: rotate(170deg);"></div>
        </div>
        <div class="gauge--labels mdl-typography--headline">
          <span class="gauge--label__low">
            <div class="flex-fill in-wrapper d-flex flex-column processed">
              <label class="in">
                {{data.concessionalAmount | currency}}
              </label>
              <h5>Processed</h5>
            </div>
          </span>
          <span class="gauge--label__spacer"></span>
          <span class="gauge--label__high">
            <div class="flex-fill out-wrapper d-flex  flex-column cap">
              <label class="out">
                {{data.concessionalCap | currency}}
              </label>
              <h5 [ngClass]="{'exceeded': data.concessionalExceeded}">Cap </h5>
            </div>
          </span>
        </div>
      </div>

    </div>
    <div class="w-50 widget">
      <h4 class="title">Non-Concessional</h4>

      <div class="gauge gauge__liveupdate" id="gauge">
        <div class="gauge--container" id="gauge-container">
          <div class="gauge--marker"></div>
          <div class="gauge--background"></div>
          <div class="gauge--center"></div>
          <div class="gauge--data"
            style="transform: rotate(-{{calculatePercentage(data.nonConcessionalAmount,data.nonConcessionalCap)}}deg);">
          </div>
          <div class="gauge--data over" *ngIf="data.nonConcessionalExceeded" style="transform: rotate(170deg);"></div>
        </div>
        <div class="gauge--labels mdl-typography--headline">
          <span class="gauge--label__low">
            <div class="flex-fill in-wrapper d-flex flex-column processed">
              <label class="in">
                {{data.nonConcessionalAmount | currency}}
              </label>
              <h5>Processed</h5>
            </div>
          </span>
          <span class="gauge--label__spacer"></span>
          <span class="gauge--label__high">
            <div class="flex-fill out-wrapper d-flex  flex-column cap">
              <label class="out">
                {{data.nonConcessionalCap | currency}}
              </label>
              <h5 [ngClass]="{'exceeded': data.nonConcessionalExceeded}">Cap </h5>
            </div>
          </span>
        </div>
      </div>

    </div>
  </div>

  <!-- <div class="table-responsive">
    <table class="table table-striped table-borderless">
      <thead>
        <tr>
          <th>Type</th>
          <th>Cap</th>
          <th>Amount Processed</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Concessional</td>
          <td>{{data.concessionalCap | currency}}</td>
          <td>{{data.concessionalAmount | currency}}</td>
        </tr>
        <tr>
          <td>Non-Concessional</td>
          <td>{{data.nonConcessionalCap | currency}}</td>
          <td>{{data.nonConcessionalAmount | currency}}</td>
        </tr>
      </tbody>
    </table>
  </div> -->
</div>
