
export class ContributionDetailModel {
  description: string = '';

  concessionalAmount: number = 0;
  concessionalCap: number = 0;

  nonConcessionalAmount: number = 0;
  nonConcessionalCap: number = 0;

}
